import styled from "styled-components";
import { colors } from "../styles/colors";
import { maerskHeadlineFontFamily } from "../styles/fonts";
import { McButton } from "@maersk-global/mds-react-wrapper/components-core/mc-button";

export default function Welcome({
  heading,
  subHeading,
  buttonLabel,
  onButtonClick,
}: Readonly<{
  heading: string;
  subHeading: string;
  buttonLabel: string;
  onButtonClick: () => void;
}>) {
  return (
    <ContentWrapper>
      <div className="content">
        <div className="headingContainer">
          <span className="headingWrapper">{heading}</span>
          <div className="subHeadingWrapper">{subHeading}</div>
        </div>
        <div className="btn-Wrapper">
          <McButton
            fit={"large"}
            id="welcome-action-button"
            label={buttonLabel}
            appearance={"primary"}
            name="add-new-brand"
            onClick={onButtonClick}
            type="button"
            variant={"filled"}
            className="btn-add-item"
          />
        </div>
      </div>
    </ContentWrapper>
  );
}

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;

  .content {
    flex-shrink: 0;
    border: 1px dashed
      ${colors["mds_brand_appearance_primary_default_text-color"]};
    padding: 50px;
    width: 700px;
    height: 320px;
  }

  .headingWrapper {
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: 56px;
    color: ${colors["mds_brand_appearance_primary_default_text-color"]};
    font-family: ${maerskHeadlineFontFamily};
  }

  .subHeadingWrapper {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: ${colors["mds_brand_appearance_primary_default_text-color"]};
    font-family: ${maerskHeadlineFontFamily};
    margin-top: 28px;
  }

  .btn-Wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .btn-add-item::part(button)  {
    min-width: 253px;
    height: 70px;
    padding: 22px;
  }
`;