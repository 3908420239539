import { useState } from "react";
import { FlexGrowBox } from "./common.styles";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { McButton } from "@maersk-global/mds-react-wrapper";
import { StaticIcon } from "./icons";
import Box from "./box";

export default function Accordian({
  headerText,
  shouldShowCreateButton,
  shouldShowEditButton,
  shouldShowDeleteButton,
  onDelete,
  id,
  keyName,
  keyValue,
  path,
  onEdit,
  onCreate,
  addButtonText,
  children,
  info,
  editButtonIcon,
  editButtonText,
}: any) {
  const [expanded, setExpanded] = useState(false);
  const handleAccordianExpansion = () => {
    setExpanded(!expanded);
  };
  return (
    <AccordianContainer className={expanded ? "withBorder" : ""}>
      <ContentWrapper>
        <Box>
          <div
            className="accordianHeader"
            onClick={handleAccordianExpansion}
            tabIndex={0}
          >
            <div
              className={`mds-text--large-normal ${
                expanded ? "expanded-font" : ""
              }`}
            >
              {headerText}
            </div>
            <StaticIcon icon={expanded ? "chevron-up" : "chevron-down"} />
          </div>
        </Box>
        <FlexGrowBox />
        <Box>
          <div className="actionButtonsWrapper">
            {shouldShowDeleteButton && (
              <McButton
                appearance="neutral"
                fit="small"
                id="delete"
                justifyitems="center"
                label="Delete"
                name="delete"
                click={() => onDelete(id, keyName, keyValue, path, info)}
                title="delete"
                type="button"
                variant="filled"
              />
            )}
            {shouldShowEditButton && (
              <McButton
                appearance="neutral"
                fit="small"
                id="edit"
                justifyitems="center"
                icon={editButtonIcon ?? ""}
                label={editButtonText ?? "Edit"}
                name="primary"
                click={() => onEdit(id)}
                title="edit"
                type="button"
                variant="filled"
              />
            )}
            {shouldShowCreateButton && (
              <McButton
                appearance="secondary"
                id="create"
                justifyitems="center"
                label={addButtonText}
                name="create"
                click={() => onCreate(id)}
                title="create"
                variant="filled"
                fit="small"
              />
            )}
          </div>
        </Box>
      </ContentWrapper>
      <HiddenContentWrapper isExpanded={expanded}>
        {children}
      </HiddenContentWrapper>
    </AccordianContainer>
  );
}

export const AccordianContainer = styled.div`
  margin-bottom: 10px;
  &.withBorder {
    border: 2px solid
      ${colors["mds_core_button_primary_filled_background-color"]};
  }
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  border: 1px solid
    ${colors["mds_brand_appearance_neutral_subtle_border-color"]};
  height: 65px;
  align-items: center;
  padding: 0px 16px 10px 16px;

  .expanded-font {
    font-weight: bold;
  }

  .accordianHeader {
    display: flex;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
    cursor: pointer;
  }
  .actionButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 11.67px;
  }
`;

export const HiddenContentWrapper = styled.section<{ isExpanded?: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? "" : "none")};
`;
