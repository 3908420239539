//import { countries } from "../../../constants/Countries";
import { Area } from "../../../models/Brand";

export const regionActions = {
  REGION_NAME_CHANGE: "REGION_NAME_CHANGE",
  AREA_NAME_CHANGE: "AREA_NAME_CHANGE",
  REGION_COUNTRY_CHANGE: "REGION_COUNTRY_CHANGE",
  ADD_AREA: "ADD_AREA",
  REMOVE_AREA: "REMOVE_AREA",
  AREA_COUNTRY_CHANGE: "AREA_COUNTRY_CHANGE",
  SET_REGION: "SET_REGION",
  RESET_REGION: "RESET_REGION"
};

export const defaultRegionState = {
  regionId: 0,
  regionName: "",
  businessBrandId: 0,
  countries: [],
  areas: [],
};

export const defaultRegion = {
  regionId: 0,
  businessBrandId: 0,
  regionName: "",
  countries: [],
  selectedCountries: [],
  mastercountries:[],
  areas: [
    {
      areaId: 0,
      areaName: "",
      countries: [],
      selectedCountries: [],
    },
  ],
};

export const regionReducer = (state: any, action: any) => {
  switch (action.type) {
    case regionActions.REGION_NAME_CHANGE:
      return { ...state, regionName: action.regionName };
    case regionActions.AREA_NAME_CHANGE:
      let areas = [...state.areas];
      areas[action.index] = {
        ...areas[action.index],
        areaName: action.areaName,
      };
      let newState = { ...state, areas: areas };
      return newState;
    case regionActions.REGION_COUNTRY_CHANGE:
      let areaCountries = state.areas.map((area: Area) => ({
        ...area,
        countries: action.countries,
        selectedCountries: area.selectedCountries.filter((country: any) =>
          action.countries.find((obj: any) => obj.label === country.label)
        ),
      }));
      return { ...state, countries: action.countries, areas: areaCountries };
    case regionActions.AREA_COUNTRY_CHANGE:
      let areasCountryChange = [...state.areas];
      areasCountryChange[action.index] = {
        ...areasCountryChange[action.index],
        selectedCountries: action.countries,
      };
      let areasCountryChangeNewState = { ...state, areas: areasCountryChange };
      return areasCountryChangeNewState;
    case regionActions.ADD_AREA:
      let areaAdd = [
        ...state.areas,
        {
          areaId: 0,
          areaName: "",
          countries: state.countries,
          selectedCountries: [],
        },
      ];
      return { ...state, areas: areaAdd };
    case regionActions.REMOVE_AREA:
      let areaRemove = state.areas.filter((area: Area, index: number) => {
        return index !== action.index;
      });
      return { ...state, areas: areaRemove };
    case regionActions.SET_REGION:
      let editableRegion = action.editableRegion;
      let mastercountries = action.mastercountries;

      if (editableRegion.regionId > 0) {
        let editableRegionCountries = editableRegion.countries.map(
          
          (country: any) => mastercountries.find((obj:any) => obj.label === country.countryName)
        );

        let areas = editableRegion.areas.map((area: Area) => {
          let areaRes = area.countries.map((country: any) =>
          mastercountries.find((obj:any) => obj.label === country.countryName)
          );
          return {
            ...area,
            selectedCountries: areaRes,
            countries: editableRegionCountries,
          };
        });
        editableRegion = {
          ...editableRegion,
          countries: editableRegionCountries,
          areas: areas,
        };
      }
      return editableRegion;
    case regionActions.RESET_REGION:
      return defaultRegionState;
    default:
      return state;
  }
};
