import { HeaderContainer } from "./layout.styles";
import icon from "../../assets/logo.png";
import { FlexGrowBox } from "../common/common.styles";
import { signOut } from "../../utils";
import { StaticIcon } from "../common/icons";
import { McList, McListItem, McMenu } from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import Box from "../common/box";

export default function appHeader(props: any) {
  return (
    <HeaderContainer>
      <Box display="flex" flexDirection="column">
        <Header display="flex" >
          <Box display="flex" style={{ alignItems: "center" }}>
            <AppNameContainer isSidebarExpanded={props.isSidebarExpanded}>
              Global Resilience & Intelligence tool
            </AppNameContainer>
          </Box>
          <FlexGrowBox />
          <Box
            display="flex"
            style={{ alignItems: "center", justifyContent: "flex-end" }}
          >
            <p className="username">{props.userName}</p>
            <UtilityContainer>
              <McMenu
                position="bottom-left"
                fit="large"
                arrow={false}
                maxwidth="150px"
                maxheight="200px"
              >
                <StaticIcon
                  icon="user"
                  size="24"
                  slot="trigger"
                />
                <McList>
                  <McListItem
                    label="Logout"
                    onClick={() => signOut()}
                  ></McListItem>
                </McList>
              </McMenu>
            </UtilityContainer>
          </Box>
        </Header>
      </Box>
    </HeaderContainer>
  );
}

const Header = styled(Box)`
  position: sticky;
  top: 4px;
  right: 0;
  z-index: 88;
  height: 80px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.03) 0px 3px 14px 2px, rgba(0, 0, 0, 0.03) 0px 8px 10px 1px;

  .mi-24px {
    font-size: 40px;
  }

  .username {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const AppNameContainer = styled.div<{ isSidebarExpanded: boolean }>`
  color: ${colors["mds_brand_appearance_primary_default_text-color"]};
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  alignitems: center;
  display: flex;
  padding: 10px 0 0 20px;
  text-transform: uppercase;
  margin-left: ${(props) => (props.isSidebarExpanded ? "265px" : "95px")};
`;

const UtilityContainer = styled.div`
  padding: 22px 24px 18px 10px;
  align-items: center;
`;
