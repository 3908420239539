import { McNotification } from "@maersk-global/mds-react-wrapper";
import styled from "styled-components";

export default function Notifications({
  description,
  variant,
}: Readonly<{
  description: string[];
  variant: "info" | "success" | "warning" | "error" | "neutral-weak";
}>) {
  let list = null;

  if (description) {
    if (description.length === 1) {
      list = <>{description[0]}</>;
    } else if (description.length > 1) {
      const listItems = description.map((desc, index) => (
        <li key={`item-${desc}`}>{desc}</li>
      ));

      list = <ul>{listItems}</ul>;
    }
  }
  return (
    <NotificationsWrapper>
      <McNotification appearance={variant} fit="small">{list}</McNotification>
    </NotificationsWrapper>
  );
}

const NotificationsWrapper = styled.div`
  margin-bottom: 5px;
`;
