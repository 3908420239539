import { FlexGrowBox } from "./common.styles";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import { useState } from "react";
import { McButton } from "@maersk-global/mds-react-wrapper";
import { StaticIcon } from "./icons";
import Box from "./box";

export default function TitleBox(props: any) {
  const [expanded, setExpanded] = useState(false);
  const handleAccordianExpansion = (id: number) => {
    setExpanded(!expanded);

    if (props.shouldLoadOnExpansion && !expanded) {
      props.onExpand(props.keyName, props.id);
    }
  };
  return (
    <>
      <TitleBoxWrapper>
        <Box>
          <div style={{ display: "flex" }}>
            <span className="titleBoxHeading">{props.title}</span>
            {props.shouldHandleExpansion && (
              <StaticIcon
                icon={expanded ? "chevron-up" : "chevron-down"}
                onClick={() => handleAccordianExpansion(props.id)}
              />
            )}
          </div>
        </Box>
        <FlexGrowBox />
        <Box display="flex" className="rightSideWrapper">
          {props.shouldShowButtons ? (
            <>
              {
                <>
                  {props.shouldShowEditButton && (
                    <McButton
                      appearance="neutral"
                      id={`Edit_${props.id}`}
                      justifyitems="center"
                      label={props.editLabel}
                      name={props.editLabel}
                      click={() => props.onEdit(props.id)}
                      title={props.label}
                      variant="filled"
                      fit="small"
                    />
                  )}
                  {props.shouldShowDeleteButton && (
                    <McButton
                      appearance="neutral"
                      id={`Delete_${props.id}`}
                      justifyitems="center"
                      label={props.deleteLabel}
                      name={props.deleteLabel}
                      click={() => props.onDelete(props.id)}
                      title={props.label}
                      variant="filled"
                      fit="small"
                    />
                  )}
                </>
              }
              <McButton
                appearance="secondary"
                id={`Create_${props.id}`}
                justifyitems="center"
                label={props.label}
                name={props.label}
                click={() => props.onCreate(props.id)}
                title={props.label}
                variant="filled"
                fit="small"
              />
            </>
          ) : (
            <></>
          )}
        </Box>
      </TitleBoxWrapper>
      {props.shouldHandleExpansion && expanded && props?.children}
    </>
  );
}

export const TitleBoxWrapper = styled(Box)`
  display: flex;
  background-color: ${colors["mds_core_background_primary_weak_color"]};
  padding: 16px 16px 16px 16px;

  .titleBoxHeading {
    font-weight: bold;
  }
  .actionButtonsWrapper {
    display: flex;
    align-items: center;
    gap: 11.67px;
  }
  .rightSideWrapper {
    gap: 8px;
  }
`;
