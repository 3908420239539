import { useEffect, useReducer } from "react";
import Welcome from "../Welcome";
import AddEditBrand from "./modals/addEditBrandModal";
import Accordian from "../../components/common/accordian";
import Heading from "../../components/common/heading";
import { getBrands } from "../../services/Brands";
import { toast } from "../../components/common/toast";
import { brandsActions, defaultBrands, brandsReducer } from "./reducers/brands";
import AddEditRegionModal from "./modals/addEditRegionModal";
import TitleBox from "../../components/common/titleBox";
import NoDataAvailable from "../../components/common/noDataAvailable";
import { FlexGrowBox } from "../../components/common/common.styles";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/common/deleteModal";
import { ArcheType, Area, Brand, Region, SiteType } from "../../models/Brand";
import {
  defaultDeleteEntityState,
  deleteActions,
  deleteReducer,
  onDeleteEntity,
} from "../../reducers/delete";
import { loadingIndicator } from "../../components/common/loading";
import {
  DeleteIcon,
  EditIcon,
  StaticIcon,
} from "../../components/common/icons";
import Tooltip from "../../components/common/tooltip";
import { Button } from "../../components/common/button";
import { McTable } from "@maersk-global/mds-react-wrapper";
import Box from "../../components/common/box";

export default function Brands(
  props: Readonly<{
    shouldShowButtons: boolean;
    shoudShowRegion: boolean;
    NavigationLink: string;
    heading: string;
    subheading: string;
  }>
) {
  const [brands, dispatch] = useReducer(brandsReducer, defaultBrands);
  const [deleteEntityState, dispatchDelete] = useReducer(
    deleteReducer,
    defaultDeleteEntityState
  );

  useEffect(() => {
    getAllBrands();
  }, []);

  const onSave = () => {
    getAllBrands();
  };

  const getAllBrands = () => {
    getBrands()
      .then((res) => {
        dispatch({ type: brandsActions.SET_LOADING, loading: false });
        dispatch({ type: brandsActions.SET_BRANDS, brands: res.brands });
        dispatch({
          type: brandsActions.SET_MASTER_COUNTRY_LIST,
          masterCountryList: res.masterCountries,
        });
      })
      .catch((err): void => {
        toast("error", err.message, "error");
      });
  };

  const addNewBrandClick = () => {
    dispatch({ type: brandsActions.RESET_EDITABLE_BRAND });
    dispatch({ type: brandsActions.SET_MODAL_OPEN, isModalOpen: true });
  };

  const addNewRegionClick = (id: number) => {
    let singleBrand = brands.brands.find((brand: Brand) => brand.id === id);
    dispatch({ type: brandsActions.SET_NEW_REGION, brandId: singleBrand.id });
    dispatch({
      type: brandsActions.SET_REGIONS_MODAL_OPEN,
      isRegionsModalOpen: true,
    });
  };

  const editBrand = (id: number) => {
    let singleBrand = brands.brands.find((brand: Brand) => brand.id === id);
    dispatch({ type: brandsActions.SET_EDITABLE_BRAND, brand: singleBrand });
    dispatch({ type: brandsActions.SET_MODAL_OPEN, isModalOpen: true });
  };

  const editRegionClick = (id: number) => {
    let singleRegion = brands.brands
      .map((brand: Brand) => brand.regions)
      .flat()
      .find((region: Region) => region.regionId === id);

    dispatch({ type: brandsActions.SET_EDITABLE_REGION, region: singleRegion });
    dispatch({
      type: brandsActions.SET_REGIONS_MODAL_OPEN,
      isRegionsModalOpen: true,
    });
  };

  const createLink = (brandId: number, archetypeId: number) => {
    return `/${props.NavigationLink}/${brandId}/${archetypeId}`;
  };

  const mapAreas = (region: Region) => {
    let regionCountriesCount = region.countries.length;
    return region.areas.map((area: Area) => {
      let areaCountriesCount = area.countries.length;
      return (
        <div
          key={`${area.areaId}_areaCountries`}
          slot={`${area.areaId}_areaCountries`}
        >
          <Box display="flex">
            <Box display="flex">
              <span>
                {areaCountriesCount +
                  " out of " +
                  regionCountriesCount +
                  " selected"}
              </span>
              {areaCountriesCount > 0 && (
                <Tooltip
                  content={area.countries.map((c) => c.countryName).join(" ,")}
                >
                  <StaticIcon
                    icon="chevron-down"
                    slot="trigger"
                    position="top-center"
                  />
                </Tooltip>
              )}
            </Box>
            <FlexGrowBox />
            <Box>
              {props.shouldShowButtons ? (
                <Tooltip content="Delete Area">
                  <DeleteIcon
                    onClick={() =>
                      onDeleteEntity(
                        dispatchDelete,
                        area.areaId,
                        "Area",
                        area.areaName,
                        "Region/area",
                        ""
                      )
                    }
                    slot="trigger"
                  />
                </Tooltip>
              ) : null}
            </Box>
          </Box>
        </div>
      );
    });
  };

  const mapBrand = (brand: Brand) => {
    return (
      <Accordian
        headerText={brand.businessBrandName}
        shouldShowCreateButton={false}
        shouldShowEditButton={props.shouldShowButtons}
        shouldShowDeleteButton={props.shouldShowButtons}
        key={brand.id}
        id={brand.id}
        path="BusinessBrand/BusinessBrand"
        keyName="BusinessBrand"
        keyValue={brand.businessBrandName}
        onEdit={editBrand}
        onCreate={editBrand}
        onDelete={() =>
          onDeleteEntity(
            dispatchDelete,
            brand.id,
            "Brand",
            brand.businessBrandName,
            "BusinessBrand/BusinessBrand",
            ""
          )
        }
        addButtonText="Add Site Type & archetypes"
        info={
          brand.siteTypes.length > 0 ||
          (brand.regions && brand.regions.length > 0)
            ? "All Sitetypes, archetypes, regions associated with this brand will get deleted."
            : ""
        }
      >
        <TitleBox
          title="Site Type"
          label="Add/Edit Sitetype and Archetypes"
          id={brand.id}
          onCreate={editBrand}
          shouldShowEditButton={false}
          shouldShowDeleteButton={false}
          shouldShowButtons={props.shouldShowButtons}
        />
        {brand.siteTypes.length === 0 ? (
          <NoDataAvailable text="No site types added" />
        ) : (
          brand.siteTypes.map((siteType: SiteType) => {
            let siteTypeData = [siteType];
            return (
              <>
                <McTable
                  columns={[
                    {
                      id: "siteTypeName",
                      sortDisabled: true,
                      renderAsHeader: true,
                    },
                  ]}
                  fit="medium"
                  data={siteTypeData}
                  headerhidden
                  disableroundedcorners
                >
                  {siteTypeData.map((row: any) => (
                    <div
                      key={`${row.id}_siteTypeName`}
                      slot={`${row.id}_siteTypeName`}
                    >
                      <Box display="flex">
                        <Box>
                          {row.siteTypeName}
                        </Box>
                        <FlexGrowBox />
                        <Box>
                          {props.shouldShowButtons ? (
                            <Tooltip content="Delete Sitetype">
                              <DeleteIcon
                                onClick={() =>
                                  onDeleteEntity(
                                    dispatchDelete,
                                    row.id,
                                    "SiteType",
                                    row.siteTypeName,
                                    "BusinessBrand/SiteType",
                                    row.archeTypes.length > 0
                                      ? "SiteType has one or more archetypes. Deleting this sitetype will delete all the archetypes associated with this sitetype."
                                      : ""
                                  )
                                }
                                slot="trigger"
                              />
                            </Tooltip>
                          ) : null}
                        </Box>
                      </Box>
                    </div>
                  ))}
                </McTable>
                {siteType.archeTypes.length === 0 ? (
                  <NoDataAvailable text="No archetypes added" />
                ) : (
                  <McTable
                    columns={[
                      {
                        id: "archeTypeName",
                        label: "Archetypes",
                        sortDisabled: true,
                      },
                    ]}
                    disableroundedcorners
                    data={siteType.archeTypes}
                    fit="medium"
                  >
                    {siteType.archeTypes.map((row: ArcheType) => (
                      <div
                        key={`${row.id}_archeTypeName`}
                        slot={`${row.id}_archeTypeName`}
                      >
                        <Box display="flex">
                          <Box>{row.archeTypeName}</Box>
                          <FlexGrowBox />
                          <Box display="flex" style={{ cursor: "pointer" }}>
                            <Tooltip content="Set up">
                              <Link
                                slot="trigger"
                                to={createLink(brand.id, row.id)}
                              >
                                <StaticIcon icon="cog" />
                              </Link>
                            </Tooltip>
                            {props.shouldShowButtons ? (
                              <Tooltip content="Delete">
                                <DeleteIcon
                                  onClick={() =>
                                    onDeleteEntity(
                                      dispatchDelete,
                                      row.id,
                                      "ArcheType",
                                      row.archeTypeName,
                                      "BusinessBrand/ArcheType",
                                      ""
                                    )
                                  }
                                  slot="trigger"
                                />
                              </Tooltip>
                            ) : null}
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </McTable>
                )}
              </>
            );
          })
        )}
        {props.shoudShowRegion && (
          <>
            <TitleBox
              title="Region"
              label="Add Region"
              id={brand.id}
              onCreate={addNewRegionClick}
              shouldShowEditButton={false}
              shouldShowDeleteButton={false}
              shouldShowButtons={props.shouldShowButtons}
            />
            {brand.regions?.length === 0 ? (
              <NoDataAvailable text="No regions added" />
            ) : (
              brand.regions?.map((region: Region) => (
                <>
                  <McTable
                    headerhidden
                    disableroundedcorners
                    datakey="regionId"
                    columns={[
                      {
                        id: "regionName",
                        label: "Region",
                        renderAsHeader: true,
                      },
                    ]}
                    data={[region]}
                    fit="medium"
                  >
                    {brand.regions?.map((row: Region) => (
                      <div
                        key={`${row.regionId}_regionName`}
                        slot={`${row.regionId}_regionName`}
                      >
                        <Box display="flex">
                          <Box>
                            {row.regionName}
                          </Box>
                          <FlexGrowBox />
                          <Box display="flex">
                            {props.shouldShowButtons && (
                              <>
                                <Tooltip content="Edit Region">
                                  <EditIcon
                                    onClick={() =>
                                      editRegionClick(row.regionId)
                                    }
                                    slot="trigger"
                                  />
                                </Tooltip>
                                <Tooltip content="Delete Region">
                                  <DeleteIcon
                                    onClick={() =>
                                      onDeleteEntity(
                                        dispatchDelete,
                                        row.regionId,
                                        "Region",
                                        row.regionName,
                                        "Region",
                                        row.areas.length > 0
                                          ? "Region has one or more areas. Deleting this region will delete all the areas associated with this region."
                                          : ""
                                      )
                                    }
                                    slot="trigger"
                                  />
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </McTable>
                  {region.areas.length === 0 ? (
                    <NoDataAvailable text="No areas added" />
                  ) : (
                    <McTable
                      disableroundedcorners
                      datakey="areaId"
                      columns={[
                        {
                          id: "areaName",
                          label: "Area",
                          sortDisabled: true,
                          width: "50%",
                        },
                        {
                          id: "areaCountries",
                          label: "Country",
                          sortDisabled: true,
                        },
                      ]}
                      data={region.areas}
                      fit="medium"
                    >
                      <>{mapAreas(region)}</>
                    </McTable>
                  )}
                </>
              ))
            )}
          </>
        )}
      </Accordian>
    );
  };

  return (
    <>
      <AddEditBrand
        isOpen={brands.isModalOpen}
        onSave={onSave}
        editableBrand={brands.editableBrand}
        onClose={() => {
          dispatch({ type: brandsActions.RESET_EDITABLE_BRAND });
          dispatch({ type: brandsActions.SET_MODAL_OPEN, isModalOpen: false });
        }}
      />
      <AddEditRegionModal
        isOpen={brands.isRegionsModalOpen}
        masterCountries={brands.masterCountryList}
        onSave={onSave}
        editableRegion={brands.editableRegion}
        onClose={() => {
          dispatch({
            type: brandsActions.SET_REGIONS_MODAL_OPEN,
            isRegionsModalOpen: false,
          });
        }}
      />
      <DeleteModal
        isOpen={deleteEntityState.isModalOpen}
        onSave={onSave}
        deleteEntity={deleteEntityState.deleteEntity}
        onClose={() => {
          dispatchDelete({
            type: deleteActions.SET_DELETE_MODAL_CLOSE,
          });
        }}
      />
      {brands.loading && loadingIndicator}

      {!brands.loading &&
        brands.brands.length === 0 &&
        props.shouldShowButtons && (
          <Welcome
            heading={"Business Brand"}
            subHeading={
              "There’s no business brands listed. Start adding one or more."
            }
            buttonLabel={"Add new brand"}
            onButtonClick={addNewBrandClick}
          />
        )}

      {!brands.loading && brands.brands.length !== 0 && (
        <>
          <Heading heading={props.heading} subheading={props.subheading}>
            {props.shouldShowButtons && (
              <Button
                id="add_brand"
                label="Add new brand"
                name="add new brand"
                click={() => {
                  dispatch({ type: brandsActions.RESET_EDITABLE_BRAND });
                  dispatch({
                    type: brandsActions.SET_MODAL_OPEN,
                    isModalOpen: true,
                  });
                }}
                title="add new brand"
                variant="filled"
                fit="large"
              />
            )}
          </Heading>
          {brands.brands.map((brand: Brand) => {
            return mapBrand(brand);
          })}
        </>
      )}
    </>
  );
}
