import { useEffect, useState } from "react";
import CommonModal from "./commonModal";
import Notifications from "./notifications";
import { deleteBusinessEntity } from "../../services/Brands";
import { toast } from "./toast";

export default function DeleteModal(props: any) {
  const [keyName, setKeyName] = useState("");
  const [key, setKey] = useState("");
  const [id, setId] = useState(0);
  const [path, setPath] = useState("");
  const [info, setInfo] = useState("");

  useEffect(() => {
    let { keyName, key, id, path, info } = props.deleteEntity;
   
    setKeyName(keyName);
    setKey(key);
    setId(id);
    setPath(path);
    setInfo(info);
  }, [props.isOpen]);

  const onModalClose = () => {
    props.onClose();
  };

  const deleteEntity = (
    id: number,
    key: string,
    keyName: string,
    path: string
  ) => {
    deleteBusinessEntity(id, path)
      .then((response) => {
        toast("Success", `${key} - ${keyName} deleted successfully`, "success");
        props.onSave();
        onModalClose();
      })
      .catch((error) => {
        toast("Error", `Some error occured while deleting. ${error.message}`, "error");
        onModalClose();
      });
  };

  return (
    <CommonModal
      primaryActionLabel="Delete"
      primaryAction={() => deleteEntity(id, key, keyName, path)}
      secondaryActionLabel="Cancel"
      onSeceondaryAction={() => onModalClose()}
      isOpen={props.isOpen}
      onModalClose={() => onModalClose()}
      heading="Delete"
      dimension={info !== "" ? "medium" : "small"}
      className={info !== "" ? "" : "delete-modal"}
    >
      {info && <Notifications description={[info]} variant={"warning"} />}
      <span>
        Are you sure you want to delete this {key} - <b>{keyName}</b>?
      </span>
    </CommonModal>
  );
}
