import { McTooltip } from "@maersk-global/mds-react-wrapper";

export default function Tooltip(props: any) {
  return (
    <McTooltip
      position={props.position || "top-left"}
      appearance={props.appearance || "neutral-inverse"}
    >
      {props.children}
      <span>{props.content}</span>
    </McTooltip>
  );
}
