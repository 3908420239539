import { createContext, useEffect, useMemo, useState } from "react";
import { AppContainer } from "./layout.styles";
import AppHeader from "./appHeader";
import SidebarLayout from "./sidebar/SidebarLayout";
import { SidebarItemsProps } from "@maersk-global/community-react-sidebar";
import { ThemeProvider } from "@maersk-global/community-react/src/index";
import {
  Roles,
  buildRolesFromTokenRoles,
  getNavItems,
  getSiteNavItems,
  getUserInfo,
} from "../../utils";
import { toast } from "../common/toast";
import { getSiteUserDashboard } from "../../services/SiteDashboard/Index";
import { UserMappedSites } from "../../models/SiteDashboard";

export const UserContext = createContext<Array<string>>([]);
export const SitesContext = createContext<{
  userMappedSites: UserMappedSites[];
}>({
  userMappedSites: [],
});

export default function Layout() {
  const [userName, setUserName] = useState<string>("");
  const [roles, setRoles] = useState<Array<string>>([]);
  const [isValidUser, setIsValidUser] = useState<boolean>(true);
  const [navItems, setNavItems] = useState<SidebarItemsProps[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [userMappedSites, setUserMappedSites] = useState<UserMappedSites[]>([]);
  const [isSiteOwner, setIsSiteOwner] = useState<boolean>(false);

  useEffect(() => {
    let userInfo = getUserInfo();

    if (userInfo && userInfo.userName && userInfo.roles) {
      setUserName(userInfo.userName);
      let concreteRoles = buildRolesFromTokenRoles(userInfo.roles);
      setRoles(concreteRoles);

      if (concreteRoles.length === 0) setIsValidUser(false);
    } else {
      setIsValidUser(false);
    }
  }, []);

  useEffect(() => {
    let isSiteOwner = roles.includes(Roles.SITEOWNER);
    setIsSiteOwner(isSiteOwner);

    if (isSiteOwner) {
      getUserMappedSites();
    } else {
      let navItems = getNavItems(roles);
      setNavItems(navItems);
    }
  }, [roles]);

  useEffect(() => {
    if (!isValidUser) {
      toast(
        "Unauthorized",
        "You do not have enough permission to access this page. Please contact your administrator.",
        "error"
      );
    }
  }, [isValidUser]);

  const getUserMappedSites = () => {
    getSiteUserDashboard()
      .then((data) => {
        setUserMappedSites(data);
      })
      .catch((error) => {
        toast("Error", error.message, "error");
        return [];
      });
  };

  useEffect(() => {
    if (isSiteOwner) {
      let siteNavItems = getSiteNavItems(userMappedSites);
      setNavItems(siteNavItems);
    }
  }, [userMappedSites, isSiteOwner]);

  const contextValue = useMemo(() => {
    return { userMappedSites };
  }, [userMappedSites]);

  return (
    <>
      {isValidUser && (
        <ThemeProvider>
          <AppContainer>
            <UserContext.Provider value={roles}>
              <AppHeader userName={userName} isSidebarExpanded={isExpanded} />
              {isSiteOwner && userMappedSites.length > 0 ? (
                <SitesContext.Provider value={contextValue}>
                  <SidebarLayout
                    navItems={navItems}
                    isExpanded={() => setIsExpanded(!isExpanded)}
                  />
                </SitesContext.Provider>
              ) : (
                <SidebarLayout
                  navItems={navItems}
                  isExpanded={() => setIsExpanded(!isExpanded)}
                />
              )}
            </UserContext.Provider>
          </AppContainer>
        </ThemeProvider>
      )}
    </>
  );
}
