import { ToastAppearance } from "@maersk-global/mds-components-core/mc-toast/types";
import { McNotification, McToast } from "@maersk-global/mds-react-wrapper";
import ReactDOM from "react-dom/client";
import { v4 as uuidv4 } from "uuid";

export const toast = (
  title: string,
  description: string,
  appearance: ToastAppearance
): Promise<void> => {
  return new Promise((resolve, _) => {
    const container = document.createElement("div");
    container.setAttribute("id", uuidv4());

    const root = ReactDOM.createRoot(document.body.appendChild(container));

    const element = (
      <McToast
        appearance={appearance}
        open
        position="top-right"
        duration={4000}
        close={() => {
          root.unmount();
          container.remove();
          resolve();
        }}
        style={{ zIndex: 9999 }}
      >
        <McNotification heading={title} body={description} />
      </McToast>
    );

    root.render(element);
  });
};
