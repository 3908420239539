import { ItemType } from "@maersk-global/community-react-tabs";

export const TabItemTypes = {
  PROCESS: "process",
  SYSTEM: "system",
  SITE: "site",
};

export const tabItemCodes = {
  site: "site",
  siteConfiguration: "site-configuration",
  impact: "impact",
  dependencies: "dependencies",
  contacts: "contacts",
  locationAndCustomers: "location-and-customers",
  continuityPlan: "continuity-plan",
  processes: "processes",
  processDependencies: "process-dependencies",
  siteDetails: "site-details",
  operationalParameters: "site-operational-parameters",
  keyCustomers: "site-key-customers",
  internalExternalContacts: "site-internal-external-contacts",
  alternativeLocation: "site-alternative-location",
};

export const tabItems: { [tabItemType: string]: Array<ItemType> } = {
  [TabItemTypes.SYSTEM]: [
    {
      id: tabItemCodes.site,
      active: true,
      title: "Site",
    },
    {
      id: tabItemCodes.siteConfiguration,
      active: false,
      title: "Site Configuration",
    },
    {
      id: tabItemCodes.impact,
      active: false,
      title: "Impact",
    },
    {
      id: tabItemCodes.dependencies,
      active: false,
      title: "Dependencies",
    },
    {
      id: tabItemCodes.contacts,
      active: false,
      title: "Contacts",
    }
  ],
  [TabItemTypes.PROCESS]: [
    {
      id: tabItemCodes.processes,
      active: true,
      title: "Impact",
    },
    {
      id: tabItemCodes.processDependencies,
      active: false,
      title: "Dependencies",
    },
    {
      id: tabItemCodes.continuityPlan,
      active: false,
      title: "Continuity Plan",
    },
  ],
  [TabItemTypes.SITE]: [
    {
      id: tabItemCodes.siteDetails,
      active: true,
      title: "Site Details",
    },
    {
      id: tabItemCodes.operationalParameters,
      active: false,
      title: "Operational Parameters",
    },
    {
      id: tabItemCodes.keyCustomers,
      active: false,
      title: "Key Customers",
    },
    {
      id: tabItemCodes.impact,
      active: false,
      title: "Impact",
    },
    {
      id: tabItemCodes.dependencies,
      active: false,
      title: "Dependencies",
    },
    {
      id: tabItemCodes.continuityPlan,
      active: false,
      title: "Continuity Plan",
    },
    {
      id: tabItemCodes.alternativeLocation,
      active: false,
      title: "Alternative Location",
    },
    {
      id: tabItemCodes.internalExternalContacts,
      active: false,
      title: "Internal & External Contacts",
    },
  ],
};
