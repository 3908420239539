import styled from "styled-components";
import { colors } from "../../styles/colors";

export default function NoDataAvailable({ text }: Readonly<{ text: string }>) {
  return (
    <NoDataAvailableWrapper data-testid={text}>
      <b>{text}</b>
    </NoDataAvailableWrapper>
  );
}

const NoDataAvailableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid
    ${colors["mds_brand_appearance_neutral_weak_background-color"]};
  color: ${colors["mds_brand_appearance_neutral_weakest_text-color"]};
`;
