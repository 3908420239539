import styled from "styled-components";
import { colors } from "../../styles/colors";
import Notifications from "./notifications";
import { useEffect, useRef } from "react";
import { McButton, McModal } from "@maersk-global/mds-react-wrapper";
import Box from "./box";

export default function CommonModal(props: any) {
  const validationMessageRef: any = useRef();

  useEffect(() => {
    if (props.errors && validationMessageRef.current) {
      validationMessageRef.current.scrollIntoView({ block: "nearest" });
    }
  }, [props.errors]);

  return (
    <McModal
      heading={props.heading}
      open={props.isOpen}
      dimension={props.dimension || "medium"}
      className={props.className}
      width={props.width || undefined}
      height={props.height || "auto"}
      backdropcloseactiondisabled
      entercloseactiondisabled
      escapecloseactiondisabled
      close={props.onModalClose}
    >
      <ContentWrapper>
        {props.errors?.errorMessage?.length > 0 && (
          <div ref={validationMessageRef}>
            <Notifications
              description={props.errors.errorMessage}
              variant={"error"}
            />
          </div>
        )}
        {props.children}
      </ContentWrapper>
      <McButton
        focusstartanchor
        slot="primaryAction"
        appearance="primary"
        click={props.primaryAction}
      >
        {props.primaryActionLabel}
      </McButton>
      <McButton
        focusendanchor
        slot="secondaryAction"
        appearance="neutral"
        variant={props.secondaryActionVariant || "neutral"}
        click={props.onSeceondaryAction}
      >
        {props.secondaryActionLabel}
      </McButton>
    </McModal>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ExpandableContentWrapper = styled.div`
  padding: 20px;
  border: 1px solid
    ${colors["mds_brand_appearance_neutral_subtle_border-color"]};
`;

export const ExpandableContentDeleteButtonWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: end;
`;

export const DeleteButtonWrapper = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: end;
  margin-top: 33px;
`;

export const AddNewContentText = styled.span`
  cursor: pointer;
  color: ${colors["mds_core_button_primary_filled_background-color"]};
  display: flex;
`;
