import styled from "styled-components";
import { colors } from "../../styles/colors";
import { maerskHeadlineFontFamily } from "../../styles/fonts";
import React from "react";

export default function heading({
  heading,
  subheading,
  children,
}: {
  heading: string;
  subheading: React.ReactNode | string;
  children?: React.ReactNode;
}) {
  return (  
      <HeadingContainer>
        <div className="headingWrapper">
          <div className="heading">{heading}</div>
          <div className="sub-heading">{subheading}</div>
        </div>
        <div className="right-wrapper">{children}</div>
      </HeadingContainer>
  );
}

export const HeadingContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .headingWrapper {
        margin-bottom: 20px;
    }
    .heading {
        color: ${ colors["mds_brand_appearance_primary_default_text-color"] };
        font-family: ${maerskHeadlineFontFamily};
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        gap: 4px;
    }

    .sub-heading {
        font-family: ${maerskHeadlineFontFamily};
        font-size: 18px,
        font-style: normal;
        font-weight: 200;
        line-height: 24px;
        color: ${ colors["mds_brand_appearance_primary_default_text-color"] };
        margin-top: 8px;
    }

    .right-wrapper {
      display: flex;
      gap: 8px;
    }
`;